import { render, staticRenderFns } from "./ClickMney.vue?vue&type=template&id=26ab41c3&scoped=true&"
import script from "./ClickMney.vue?vue&type=script&lang=js&"
export * from "./ClickMney.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26ab41c3",
  null
  
)

export default component.exports